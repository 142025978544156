/* eslint-disable no-throw-literal */
import { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
} from "firebase/auth";
import { auth } from "../config/firebase";
import { Backdrop, CircularProgress } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { useAppDispatch } from "../hooks";
import { clearState } from "../features/itemSlice";

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      (firebaseUser) => {
        setUser(firebaseUser);
        setLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );

    return unsubscribe;
  }, []);
  if (loading) {
    return (
      <div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }

  const errorDetails = (e: FirebaseError): string => {
    if (e.message === "auth/invalid-email") {
      return "Invalid Email Address";
    } else if (e.code === "auth/wrong-password") {
      return "Incorrect Password";
    } else if (e.code === "auth/too-many-requests") {
      return "Too many attempts, try later.";
    } else if (e.code === "auth/user-not-found") {
      return "Email not found, please create new account.";
    } else if (e.code === "auth/email-already-in-use") {
      return "Email already in use.";
    } else {
      return "An error occurred";
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login: async ({ email, password }): Promise<Record<string, string>> => {
          try {
            await signInWithEmailAndPassword(auth, email, password);
          } catch (e) {
            if (e instanceof FirebaseError) {
              throw { error: errorDetails(e) };
            }
          }
          return {};
        },
        signUp: async ({
          email,
          password,
        }): Promise<Record<string, string>> => {
          try {
            await createUserWithEmailAndPassword(auth, email, password);
          } catch (e) {
            if (e instanceof FirebaseError) {
              throw { error: errorDetails(e) };
            }
          }
          return {};
        },
        logOut: async () => {
          console.log("logged out");
          dispatch(clearState());
          await signOut(auth);
        },
        verifyEmail: async () => {
          if (user) await sendEmailVerification(user);
        },
        sendPasswordResetEmail: async ({ email }) => {
          try {
            await sendPasswordResetEmail(auth, email);
          } catch (e) {
            throw { error: "Email not found" };
          }
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
