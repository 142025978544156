import {
  CssBaseline,
  Container,
  Slide,
  useScrollTrigger,
  Avatar,
  Grid,
  Chip,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import React from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import yamijo from "../assets/images/yamijo.png";
import phones from "../assets/images/phones.png";
import android from "../assets/images/android.png";
import ios from "../assets/images/ios.png";

import {
  faCartPlus,
  faGrinWink,
  faHandshake,
  faSearch,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HideOnScroll() {
  return (
    <Slide appear={false} direction="down" in={!useScrollTrigger()}>
      <Container
        sx={
          {
            // position: "absolute",
            // top: "10vw",
          }
        }
      >
        <Avatar src={yamijo} sx={{ m: 1, width: "15vw", height: "15vw" }} />
      </Container>
    </Slide>
  );
}

export function Landing() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/sign-in");
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        className="landingBackground"
        maxWidth={false}
        sx={{
          height: "auto",
          backgroundImage:
            "linear-gradient(to top, rgba(255, 255, 255, 1), rgba(2, 29, 50, 1))",
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            pt: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Avatar src={yamijo} sx={{ m: 1, width: "5vw", height: "5vw" }} />
            <Chip
              label="About us"
              // variant="outlined"
              className="navigationChip"
            />
            <Chip
              label="FAQs"
              className="navigationChip"
              // onClick={handleClick}
            />
            <Chip
              label="Features"
              className="navigationChip"
              // onClick={handleClick}
            />
            <Chip
              label="Sign in"
              className="navigationChip"
              onClick={handleClick}
            />
          </Container>
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "500px" }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontSize: "9vw",
                    color: "white",
                    fontWeight: "900",
                  }}
                >
                  YamiJo
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "600",
                    // paddingBottom: "100px",
                  }}
                >
                  Household items sharing platform
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    fontSize: "20px",
                    color: "#021d32",
                    fontWeight: "600",
                    paddingBottom: "100px",
                  }}
                >
                  Why buy{" "}
                  <FontAwesomeIcon
                    icon={faGrinWink}
                    style={{
                      color: "#021d32",
                      fontSize: "20px",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              display={{ xs: "none", lg: "block", xl: "block" }}
              sx={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                height: "600px",
                width: "100%",
                backgroundImage: `url(${phones})`,
              }}
            ></Grid>
          </Container>
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <img src={android} alt="this is car" width="33%" />
            </Grid>
            <Grid item xs={6}>
              <img src={android} alt="this is car" width="33%" />
            </Grid>
          </Container>
        </Container>
      </Container>

      {/* <Container
        maxWidth={false}
        sx={
          {
            // pb: "10px",
            // backgroundImage: `url(${dots})`,
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "contain",
          }
        }
      > */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        sx={{ pb: "80px" }}
      >
        <Grid item lg={12} md={12} display={{ xs: "none", md: "block" }}>
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="inherit" variant="outlined">
                  <FontAwesomeIcon icon={faShareAlt} className="landingIcon" />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontSize: "30px", fontWeight: 600 }}
                >
                  Share
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                  Share items with your neighbors
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector sx={{ height: "100px" }} />
                <TimelineDot color="inherit" variant="outlined">
                  <FontAwesomeIcon icon={faSearch} className="landingIcon" />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ pt: "120px", px: 2 }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontSize: "30px", fontWeight: 600 }}
                >
                  Search
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                  Search for available items
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector sx={{ height: "100px" }} />
                <TimelineDot color="inherit" variant="outlined">
                  <FontAwesomeIcon icon={faCartPlus} className="landingIcon" />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ pt: "120px", px: 2 }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontSize: "30px", fontWeight: 600 }}
                >
                  Request
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                  Send a request to item owner
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector sx={{ height: "100px" }} />
                <TimelineDot color="inherit" variant="outlined">
                  <FontAwesomeIcon icon={faHandshake} className="landingIcon" />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ pt: "120px", px: 2 }}>
                <Typography
                  variant="h6"
                  component="span"
                  sx={{ fontSize: "30px", fontWeight: 600 }}
                >
                  Return
                </Typography>
                <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
                  {" "}
                  Return in good condition
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
        <Grid
          container
          display={{ lg: "none", xl: "none", md: "none" }}
          spacing={5}
          sx={{
            p: "10px",
          }}
        >
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <FontAwesomeIcon
                  icon={faShareAlt}
                  style={{
                    color: "#021d32",
                    fontSize: "30px",
                    padding: "10px",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Share
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Share items with your neighbors
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    color: "#021d32",
                    fontSize: "30px",
                    padding: "10px",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Search
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Search for available items
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <FontAwesomeIcon
                  icon={faCartPlus}
                  style={{
                    color: "#021d32",
                    fontSize: "30px",
                    padding: "10px",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Request
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Send a request to item owner
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{
                borderRadius: "20px",
              }}
            >
              <CardActionArea>
                <FontAwesomeIcon
                  icon={faHandshake}
                  style={{
                    color: "#021d32",
                    fontSize: "30px",
                    padding: "10px",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Return
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    Return in good condition
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* </Container> */}

      {/* <Grid item xs={4}>
        <Item>
          <Container sx={{ fontSize: "20px", textAlign: "justify" }}>
            <Box sx={{ my: 2 }}>
              {[...new Array(12)]
                .map(
                  () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
                )
                .join("\n")}
            </Box>
          </Container>
        </Item>
      </Grid>
      <Grid item xs={8}>
        <Item>
          <Container sx={{ fontSize: "20px", textAlign: "justify" }}>
            <Box sx={{ my: 2 }}>
              {[...new Array(12)]
                .map(
                  () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
                )
                .join("\n")}
            </Box>
          </Container>
        </Item>
      </Grid> */}
      {/* </Container> */}
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: "#021d32",

          // padding: "100px",
          // marginTop: "100px",
        }}
      >
        <Grid
          container
          direction="row"
          color="white"
          justifyContent="space-around"
          alignItems="center"
          sx={{ height: "300px" }}
        >
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={3}>
                <Link
                  href="#"
                  variant="h6"
                  underline="none"
                  fontWeight="bolder"
                >
                  Terms & Conditions
                </Link>
              </Grid>
              <Grid item xs={3}>
                <Link
                  href="#"
                  variant="h6"
                  underline="none"
                  fontWeight="bolder"
                >
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item xs={3}>
                <Link
                  href="#"
                  variant="h6"
                  underline="none"
                  fontWeight="bolder"
                >
                  Cookie Policy
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Typography
                variant="h6"
                align="center"
                sx={{ fontSize: "15px", color: "white", fontWeight: "900" }}
              >
                Registered in Scotland with no. SC740892 Registered Office: 30
                Anchor Drive, Paisley.
              </Typography>
            </Grid>
          </Grid>

          {/* <Grid item md={4}>
            <Card
              sx={{
                borderRadius: "20px",
                backgroundColor: "transparent",
                color: "white",
              }}
            >
              <CardActionArea>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    // color: "#021d32",
                    fontSize: "50px",
                    padding: "20px",
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h4" component="div">
                    Lizard
                  </Typography>
                  <Typography variant="h5">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid> */}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
