import {
  Modal,
  Box,
  Typography,
  styled,
  Avatar,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import { FC, Fragment } from "react";
import { isLoading, selectedItem } from "../features/itemSlice";
import { useAppSelector } from "../hooks";
import { RequestStatus } from "../types/types";
import CountDown from "./countDown";
import LoadingSpinner from "./loadingSpinner";
import SelectPeriod from "./selectPeriod";

const ItemDetailsImage = styled(Box)(
  () => `
  height: 60%;
  margin-bottom: 20px
`
);

interface IItemDetailsProps {
  open: boolean;
  handleClose: () => void;
}

export const ItemDetailsModal: FC<IItemDetailsProps> = ({
  open,
  handleClose,
}) => {
  const response = useAppSelector(selectedItem);
  const loading = useAppSelector(isLoading);
  return (
    <Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        response && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box className="modal-itemDetails">
              <ItemDetailsImage className="img-gradient">
                <img
                  src={response.downloadUrl}
                  width="100%"
                  height="100%"
                  alt=""
                />
              </ItemDetailsImage>
              <Typography fontSize={20} fontWeight="bold">
                Description
              </Typography>
              <Divider />

              <Typography
                color="text.secondary"
                fontSize={15}
                paddingBottom={4}
              >
                {response.description ?? 0}
              </Typography>

              {response.transactions && (
                <>
                  <Typography fontSize={20} fontWeight="bold" paddingBottom={1}>
                    Transactions
                  </Typography>
                  <Divider
                    sx={{
                      marginBottom: "5px",
                    }}
                  />
                </>
              )}

              {response.transactions &&
                response.transactions.map((transaction) => {
                  return (
                    <div key={transaction.accessKey}>
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          <Avatar
                            style={{ margin: "auto" }}
                            alt=""
                            src={`${transaction.avatarUrl}`}
                            sx={{
                              width: 56,
                              height: 56,
                              alignContent: "center",
                            }}
                          />
                        </Grid>
                        <Grid xs={5} item>
                          <Grid container direction="column">
                            <Grid xs item>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                fontSize={13}
                                fontWeight={"bold"}
                              >
                                {transaction.firstName} {transaction.lastName}
                              </Typography>
                              <Grid container direction="column">
                                <Grid xs item>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontSize={9}
                                    fontStyle={"italic"}
                                  >
                                    <Typography
                                      component="span"
                                      color="text.secondary"
                                      fontSize={10}
                                      fontWeight={"bold"}
                                      fontStyle={"italic"}
                                    >
                                      Created {" :  "}
                                    </Typography>

                                    {new Date(
                                      Date.parse(transaction.created)
                                    ).toLocaleDateString()}
                                  </Typography>
                                </Grid>
                                <Grid xs item>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    fontSize={9}
                                    fontStyle={"italic"}
                                  >
                                    <Typography
                                      component="span"
                                      color="text.secondary"
                                      fontSize={10}
                                      fontWeight={"bold"}
                                      fontStyle={"italic"}
                                    >
                                      Period {" :  "}
                                    </Typography>

                                    {dateDifference(
                                      transaction.created,
                                      transaction.dueDate
                                    )}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs>
                          {renderAction(
                            transaction.status,
                            transaction.dueDate
                          )}
                        </Grid>
                      </Grid>
                      <Divider
                        sx={{
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      />
                    </div>
                  );
                })}
              {response.ownerId && <SelectPeriod />}
            </Box>
          </Modal>
        )
      )}
    </Fragment>
  );
};

const dateDifference = (startDate: string, endDate: string): string => {
  const diff: number =
    Math.round(
      (Date.parse(endDate) - Date.parse(startDate)) / (1000 * 60 * 60 * 24)
    ) - 1;
  return diff > 1 ? `${diff} days` : `${diff} day`;
};

const renderAction = (status: RequestStatus, dueDate: string): JSX.Element => {
  switch (status) {
    case RequestStatus.pending:
      return (
        <Grid container direction="row">
          <Grid xs item>
            <Button variant="contained" size="small">
              Approve
            </Button>
          </Grid>
          <Grid xs item>
            <Button variant="contained" size="small" color="error">
              Decline
            </Button>
          </Grid>
        </Grid>
      );
    case RequestStatus.declined:
      return (
        <Typography
          variant="body2"
          color="red"
          fontSize={13}
          textAlign="center"
          fontWeight={"bold"}
        >
          Declined
        </Typography>
      );
    case RequestStatus.completed:
      return (
        <Typography
          variant="body2"
          color="green"
          fontSize={13}
          textAlign="center"
          fontWeight={"bold"}
        >
          Completed
        </Typography>
      );
    case RequestStatus.pendingCollection:
      return (
        <Typography
          variant="body2"
          color="green"
          fontSize={13}
          textAlign="center"
          fontWeight={"bold"}
        >
          Pending collection
        </Typography>
      );
    case RequestStatus.pendingReturn:
      return (
        <Grid item>
          <Typography
            variant="body2"
            color="green"
            fontSize={13}
            textAlign="center"
            fontWeight={"bold"}
          >
            Pending return
          </Typography>
          <CountDown dueDate={dueDate} key={dueDate} />
        </Grid>
      );

    default:
      return <></>;
  }
};
