import { Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";

const CountDown: FC<{ dueDate: string }> = ({ dueDate }) => {
  const currentTime = Date.now();
  const dueDateMilliseconds = Date.parse(dueDate);
  const formatTimeLeft = (): Record<string, string> | {} => {
    const difference = dueDateMilliseconds - currentTime;
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(difference / (1000 * 60 * 60))
          .toString()
          .padStart(2, "0"),
        minutes: Math.floor((difference / 1000 / 60) % 60)
          .toString()
          .padStart(2, "0"),
        seconds: Math.floor((difference / 1000) % 60)
          .toString()
          .padStart(2, "0"),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(formatTimeLeft());

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval, i) => {
    timerComponents.push(
      <span key={i}>
        {timeLeft[interval]}
        {(interval === "hours" || interval === "minutes") && " : "}
      </span>
    );
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(formatTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });
  return (
    <Typography
      variant="body2"
      color="green"
      fontSize={10}
      textAlign="center"
      fontWeight={"bold"}
    >
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </Typography>
  );
};

export default CountDown;
