import { Container, Box, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function Page404() {
  return (
    <Container style={{ height: "100vh", display: 'flex'}}>
      <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
          mistyped the URL? Be sure to check your spelling.
        </Typography>

        <Box
          style={{ display: "block" }}
          component="img"
          src="../logo.png"
          sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
        />
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Go to Home
        </Button>
      </Box>
    </Container>
  );
}