import { Fab } from "@mui/material";
import { CSSProperties, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TitleBar } from "../component/titleBar";
import { AuthContext } from "../context/AuthContext";
import { getCategories, selectCategory } from "../features/categorySlice";
import { getItems, selectItems } from "../features/itemSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { IAuth } from "../types/types";
import MainBody from "../component/mainBody";
import { getUserData } from "../features/userSlice";
import LoadingSpinner from "../component/loadingSpinner";

const fabStyle: CSSProperties = {
  position: "fixed",
  bottom: 30,
  right: 30,
};

export function Store() {
  const { user } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();
  const categories = useAppSelector(selectCategory);
  const items = useAppSelector(selectItems);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user || !user.emailVerified) {
      navigate("/");
      return;
    } else {
      dispatch(getCategories());
      dispatch(getItems());
      dispatch(getUserData());
    }
  }, [user, navigate, dispatch]);

  return (
    <>
      <main>
        <TitleBar />
        {!items && !categories ? (
          <LoadingSpinner />
        ) : (
          <>
            <MainBody categories={categories} items={items} />
            <Fab
              variant="extended"
              color="primary"
              aria-label="add"
              style={fabStyle}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                color="white"
                style={{ paddingRight: "10px" }}
                size={"2x"}
              />
              Add item
            </Fab>
          </>
        )}
      </main>
    </>
  );
}
