import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import PageNotFound from "./component/pageNotFound";
import SignIn from "./component/signIn";
import { Home } from "./pages/home";
import { EmailVerification } from "./pages/emailVerification";
import { Store } from "./pages/store";
import { SendPasswordReset } from "./pages/sendPasswordReset";
import { Landing } from "./pages/landing";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Sign-in" element={<SignIn />} />
          <Route path="/home" element={<Home />} />
          <Route path="/store" element={<Store />} />
          <Route path="/emailVerification" element={<EmailVerification />} />
          <Route path="/sendPasswordReset" element={<SendPasswordReset />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
