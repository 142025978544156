import { Card, CardMedia, Typography, CardActions, Chip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { categoryColor, Item } from "../types/types";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectCategory } from "../features/categorySlice";
import { getItem } from "../features/itemSlice";
import { FC, useState } from "react";
import { ItemDetailsModal } from "./itemDetailsModal";

const ItemCard: FC<Item> = (item) => {
  const categories = useAppSelector(selectCategory);
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <>
      <div style={{ position: "relative", cursor: "pointer" }}>
        <Card
          onClick={() => {
            if (item?.borrowed && item.borrowed!) {
              return;
            }
            dispatch(
              getItem({ itemId: item.accessKey!, ownerId: item.ownerId! })
            );
            toggleModal();
          }}
          elevation={0}
          className="img-gradient"
          sx={{
            margin: "10px",
            zIndex: 1,
            "::after": {
              backgroundImage: item?.borrowed
                ? "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #e40a0a 100%)"
                : "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);",
            },
          }}
        >
          <CardMedia
            component="img"
            image={item.downloadUrl}
            alt={item.downloadUrl}
            sx={{ height: "250px", width: "250px" }}
          />

          <Typography
            variant="body2"
            color="white"
            position={"absolute"}
            bottom={50}
            right={20}
            fontWeight={"bold"}
            fontSize={17}
            zIndex={1}
          >
            {item.name}
          </Typography>
          <Chip
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: `${categoryColor[item.category!]}`,
              fontWeight: "bold",
              position: "absolute",
              right: 15,
              bottom: 15,
              fontSize: 10,
              zIndex: 1,
              "&:hover": {
                backgroundColor: `${categoryColor[item.category!]}`,
              },
            }}
            // clickable
            label={
              categories[item.category!] && categories[item.category!].label
            }
          />
          <CardActions disableSpacing>
            <FontAwesomeIcon
              icon={faEye}
              color="white"
              size="xs"
              style={{ paddingRight: "3px", zIndex: 1 }}
            />
            <Typography variant="body2" color="white" fontSize={10} zIndex={1}>
              {item.views ?? 0}
            </Typography>
          </CardActions>
        </Card>
        <ItemDetailsModal open={openModal} handleClose={toggleModal} />
      </div>
    </>
  );
};
export default ItemCard;
