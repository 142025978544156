import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { Api } from "../service/api";
import { Item } from "../types/types";

// Define the initial state using that type
const initialState: {
  allItems: Item[];
  selectedItem: Item | undefined;
  isLoading: boolean;
} = {
  allItems: [],
  selectedItem: undefined,
  isLoading: false,
};

export const getItems = createAsyncThunk(
  "item/getItems",
  (others: boolean = false) => {
    return Api.getItems(others);
  }
);

export const getItem = createAsyncThunk(
  "item/getItem",
  (data: Record<string, string>) => {
    const { itemId, ownerId } = data;
    return Api.getItem(itemId, ownerId);
  }
);

export const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    clearState: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItems.pending, (state, action) => {
      state.isLoading = true;
      // console.log(action)
    });
    builder.addCase(
      getItems.fulfilled,
      (state, action: PayloadAction<Item[]>) => {
        state.isLoading = false;
        state.allItems = action.payload;
        // return action.payload
      }
    );
    builder.addCase(getItems.rejected, (state, action) => {
      state.isLoading = false;
      // console.log(action)
    });

    builder.addCase(getItem.pending, (state, action) => {
      state.selectedItem = undefined;
      state.isLoading = true;
    });
    builder.addCase(getItem.fulfilled, (state, action: PayloadAction<Item>) => {
      state.isLoading = false;
      state.selectedItem = action.payload;
    });
    builder.addCase(getItem.rejected, (state, action) => {
      state.selectedItem = undefined;
      state.isLoading = false;
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectItems = (state: RootState) => state.item.allItems;
export const selectedItem = (state: RootState) => state.item.selectedItem;
export const isLoading = (state: RootState) => state.item.isLoading;
export const { clearState } = itemSlice.actions;
export default itemSlice.reducer;
