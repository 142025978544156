import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../component/loadingSpinner";
import MainBody from "../component/mainBody";
import { TitleBar } from "../component/titleBar";
import { AuthContext } from "../context/AuthContext";
import { getCategories, selectCategory } from "../features/categorySlice";
import { getItems, selectItems } from "../features/itemSlice";
import { getUserData } from "../features/userSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IAuth } from "../types/types";

export function Home() {
  const { user } = useContext(AuthContext) as IAuth;
  const navigate = useNavigate();
  const categories = useAppSelector(selectCategory);
  const items = useAppSelector(selectItems);
  const dispatch = useAppDispatch();
  const [isConnecting, setConnect] = useState<boolean>(false);

  useEffect(() => {
    if (!user || !user.emailVerified) {
      navigate("/");
      return;
    } else {
      setConnect(true);
      dispatch(getCategories());
      dispatch(getItems(true));
      dispatch(getUserData());
      setConnect(false);
    }
  }, [user, navigate, dispatch, isConnecting]);

  return (
    <>
      {isConnecting ? (
        <LoadingSpinner />
      ) : (
        <main>
          <TitleBar />
          {!items && !categories ? (
            <LoadingSpinner />
          ) : (
            <MainBody categories={categories} items={items} />
          )}
        </main>
      )}
    </>
  );
}
