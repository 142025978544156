import { Menu, MenuItem, Avatar, Divider, ListItemIcon } from "@mui/material";
import { FC, Fragment } from "react";
import { getUserData, selectUser } from "../features/userSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleRoof,
  faShopLock,
  faTowerBroadcast,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { IAuth } from "../types/types";

interface IMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
  logOut: IAuth["logOut"];
}

const AccountMenu: FC<IMenuProps> = ({
  anchorEl,
  open,
  handleClose,
  logOut,
}) => {
  const userData = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigator = useNavigate();

  return (
    <Fragment>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ fontWeight: "bolder" }}>
          <Avatar
            sx={{ width: 35, height: 35, border: 1 }}
            src={userData.imageUrl}
          ></Avatar>
          My account
        </MenuItem>
        <MenuItem
          onClick={() => navigator("/home")}
          sx={{ fontWeight: "bolder" }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faPeopleRoof} />
          </ListItemIcon>
          Public Square
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => navigator("/store")}
          sx={{ fontWeight: "bolder" }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faShopLock} />
          </ListItemIcon>
          My Store
        </MenuItem>
        <MenuItem
          onClick={() => dispatch(getUserData())}
          sx={{ fontWeight: "bolder" }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faTowerBroadcast} />
          </ListItemIcon>
          Adverts
        </MenuItem>

        <MenuItem onClick={logOut} sx={{ fontWeight: "bolder" }}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowAltCircleRight} color="red" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default AccountMenu;
