

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../store/store'
import { Api } from "../service/api"
import { ICategory } from "../types/types";


// Define the initial state using that type
const initialState: ICategory[] = []

export const getCategories = createAsyncThunk('category/getCategories', async () => {
    return Api.getCategories()
})

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCategories.pending, (_, __) => {
            // console.log(action)
        });
        builder.addCase(getCategories.fulfilled, (state, action: PayloadAction<String[]>) => {
            const catObj: ICategory[] = action.payload.map((category, i) => ({label: category, key: i} as ICategory))
            Object.assign(state, catObj )
        })
        builder.addCase(getCategories.rejected, (_, __) => {
            // console.log(action)
        })
    },
})


// Other code such as selectors can use the imported `RootState` type
export const selectCategory = (state: RootState) => state.categories;

export default categorySlice.reducer