import { User } from "firebase/auth";

export interface IUserModel {
  address: string;
  dob: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  imageUrl: string;
}

export interface ICategory {
  key: number;
  label: string;
}

export enum RequestStatus {
  pending = "pending",
  accepted = "accepted",
  declined = "declined",
  completed = "completed",
  pendingCollection = "pendingCollection",
  pendingReturn = "pendingReturn",
}
export interface IRequest {
  itemId: string;
  ownerId: number;
  lenderId: string;
  created: string;
  accessKey: string;
  status: RequestStatus;
  lastName: string;
  firstName: string;
  avatarUrl: string;
  dueDate: string;
}
export interface Item {
  name?: string;
  description?: string;
  category?: number;
  downloadUrl?: string;
  accessKey?: string;
  status?: ItemStatusEnum;
  ownerId?: string;
  views?: number;
  borrowed?: boolean;
  transactions?: [IRequest];
}

export enum ItemStatusEnum {
  available,
  unavailable,
  archived,
}

export const categoryColor: String[] = [
  "#FFEB3B",
  "#2196F3",
  "#F44336",
  "#4CAF50",
  "#FFFF00",
  "#69F0AE",
  "#FFAB40",
  "#FF5252",
  "#448AFF",
];

export interface IAuth {
  user: User | null;
  login: ({ email, password }) => Promise<Record<string, string>>;
  signUp: ({ email, password }) => Promise<Record<string, string>>;
  logOut: () => void;
  sendPasswordResetEmail: ({ email: string }) => Promise<void>;
  verifyEmail: () => void;
}
