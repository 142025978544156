import {
  Box,
  ButtonProps,
  Button,
  styled,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { useState } from "react";
// import { deepOrange, deepPurple } from "@mui/material/colors";

const StyledButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: "green",
  "&:hover": {
    backgroundColor: "green",
  },
}));

export default function SelectPeriod(): JSX.Element {
  const [value, setValue] = useState("1");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Box
      sx={{
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
        }}
      >
        <Typography fontSize={100} fontWeight="bold" textAlign="center">
          {value} {parseInt(value) > 1 ? " days" : " day"}
        </Typography>
      </Box>
      <FormControl
        sx={{
          width: "100%",
          alignItems: "center",
        }}
      >
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="1" />
          <FormControlLabel value="2" control={<Radio />} label="2" />
          <FormControlLabel value="3" control={<Radio />} label="3" />
          <FormControlLabel value="4" control={<Radio />} label="4" />
          <FormControlLabel value="5" control={<Radio />} label="5" />
        </RadioGroup>
      </FormControl>
      <StyledButton
        fullWidth
        size="medium"
        variant="contained"
        sx={{ borderRadius: 28 }}
      >
        Request
      </StyledButton>
    </Box>
  );
}
