import {
  AppBar,
  Avatar,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Fingerprint } from "@mui/icons-material";

import AccountMenu from "./accountMenu";
import { IAuth } from "../types/types";
import yamijo from "../assets/images/yamijo.png";

export function TitleBar() {
  const { logOut } = useContext(AuthContext) as IAuth;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <AppBar
        position="static"
        elevation={10}
        sx={{
          marginBottom: "20px",
          backgroundColor:
            process.env.NODE_ENV === "development" ? "orange" : "#021d32",
        }}
      >
        <Container>
          <Toolbar>
            <IconButton
              edge="start"
              aria-label="menu"
              sx={{ mr: 1, color: "#f6fafd" }}
            >
              <Avatar src={yamijo} />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 5,
                textAlign: "left",
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {/* Yamijo */}
            </Typography>

            <Tooltip title="Account">
              <IconButton
                onClick={handleClick}
                // size="large"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Fingerprint color="inherit"></Fingerprint>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </Container>
      </AppBar>
      <AccountMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        logOut={logOut}
      />
    </Fragment>
  );
}
