import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '../store/store'
import { Api } from "../service/api"
import { IUserModel } from "../types/types";


// Define the initial state using that type
const initialState: IUserModel = {
    address: '',
    dob: 0,
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    imageUrl: ''
}

export const getUserData = createAsyncThunk('user/getUserData', async () => {
    return Api.getUserData()
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserData.pending, (_, __) => {
            // console.log(action)
        });
        builder.addCase(getUserData.fulfilled, (state, action: PayloadAction<IUserModel>) => {
            Object.assign(state, { ...action.payload })
        })
        builder.addCase(getUserData.rejected, (_, __) => {
            // console.log(action)
        })
    },
})


// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer