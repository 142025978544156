import {
  CssBaseline,
  Container,
  Collapse,
  Alert,
  Box,
  Avatar,
  Typography,
  Button,
  AlertColor,
} from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "../component/copyRight";
import { IAuth } from "../types/types";
// import { useNavigate } from "react-router-dom";

// const theme = createTheme();

export function EmailVerification() {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [severity, setSeverity] = useState<AlertColor>("error");
  const { user, verifyEmail } = useContext(AuthContext) as IAuth;
  // const navigate = useNavigate();

  const onClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowAlert(true);
    setSeverity("success");
    setAlertMessage("Check your email");
    verifyEmail();
  };

  // useEffect(()=> {
  //   {!user && navigate('/')}
  //   {user && navigate('/about')}
  // })

  return (
    <Container
      component="main"
      sx={{ height: "100vh", alignContent: "center", display: "flex" }}
    >
      <CssBaseline />
      <Container
        style={{
          position: "absolute",
          top: 0,
          paddingTop: "50px",
        }}
      >
        {showAlert && (
          <Collapse in={showAlert}>
            {
              <Alert
                sx={{
                  "& .MuiAlert-message": {
                    width: "100%",
                  },
                }}
                severity={severity}
                onClick={() => setShowAlert(false)}
              >
                {alertMessage}
              </Alert>
            }
          </Collapse>
        )}
      </Container>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ width: "50%" }}>
          Hi {user?.email}, thanks for registering. Please click the link below
          to verify your email
        </Typography>
        <Button
          onClick={onClickHandler}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 2, width: "50%" }}
        >
          Verify Email
        </Button>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Container>
  );
}
