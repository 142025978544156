import {
  CssBaseline,
  Container,
  Collapse,
  Alert,
  Box,
  Avatar,
  Typography,
  Button,
  AlertColor,
  Grid,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "../component/copyRight";
import { IAuth } from "../types/types";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

type userData = { email: string };
export function SendPasswordReset() {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [severity, setSeverity] = useState<AlertColor>("error");
  const { sendPasswordResetEmail } = useContext(AuthContext) as IAuth;

  const onClickHandler = async (data: userData) => {
    try {
      await sendPasswordResetEmail({...data});
      setShowAlert(true);
      setAlertMessage("Check your email");
      setSeverity("success");
    } catch (e: any) {
      setSeverity("error");
      setShowAlert(true);
      setAlertMessage(e.error);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<userData>();

  return (
    <>
      <Container
        component="main"
        sx={{ height: "100vh", alignContent: "center", display: "flex" }}
      >
        <CssBaseline />
        <Container
          style={{
            position: "absolute",
            top: 0,
            paddingTop: "50px",
          }}
        >
          {showAlert && (
            <Collapse in={showAlert}>
              {
                <Alert
                  sx={{
                    "& .MuiAlert-message": {
                      width: "100%",
                    },
                  }}
                  severity={severity}
                  onClick={() => setShowAlert(false)}
                >
                  {alertMessage}
                </Alert>
              }
            </Collapse>
          )}
        </Container>
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          display="flex"
          container
        >
          <Container
            style={{
              position: "absolute",
              top: 0,
              paddingTop: "20px",
              width: "70%",
            }}
          ></Container>
          <Box
            sx={{
              mx: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#021d32" }} variant="rounded">
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onClickHandler)}
              sx={{ mt: 1, width: "70%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                // onChange={handleChange}
                id="email"
                label="Email Address"
                autoComplete="email"
                error={errors.email ? true : false}
                {...register("email", {
                  required: "Please Enter Your Email!",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please Enter A Valid Email!",
                  },
                })}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send
              </Button>
            </Box>
          </Box>
          <Button to="/" size="large" variant="text" component={Link}>
          Go to Home
        </Button>
          <Copyright />
        </Grid>
      </Container>
    </>
  );
}
