import axios from "axios";
import { auth } from "../config/firebase";
import { Item, IUserModel } from "../types/types";

const axiosInterceptor = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD,
});

axiosInterceptor.interceptors.request.use(async function (config) {
  config.headers!.Authorization = `Bearer ${await auth.currentUser!.getIdToken()}`;
  return config;
});

export class Api {
  static async getUserData(): Promise<IUserModel> {
    return axiosInterceptor
      .get("/user")
      .then((response) => response.data as IUserModel);
  }

  static async getCategories(): Promise<String[]> {
    return axiosInterceptor
      .get("/category")
      .then((response) => response.data as String[]);
  }

  static async getItem(itemId: string, ownerId?: string): Promise<Item> {
    return axiosInterceptor
      .get(`/items/${itemId}`, { params: { ownerId, timestamp: Date.now() } })
      .then((response) => response.data as Item);
  }

  static async getItems(others: boolean = false): Promise<Item[]> {
    return axiosInterceptor
      .get("/items", { params: { others, timestamp: Date.now() } })
      .then((response) => response.data as Item[]);
  }
}

export default Api;
