import { Chip, Container, Grid, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ItemCard from "../component/itemCard";
import { categoryColor, ICategory, Item } from "../types/types";
import LoadingSpinner from "./loadingSpinner";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0),
  padding: "2px",
}));

type mainBody = {
  categories: ICategory[];
  items: Item[];
};
const MainBody: FC<mainBody> = ({ categories, items }) => {
  const [selectedCategory, setCategory] = useState<number>(-1);
  const [filteredList, setFilteredList] = useState<Item[]>([]);

  useEffect(() => {
    setFilteredList(items);
  }, [items]);
  const categoryHandler = (key: number) => {
    if (key === selectedCategory) {
      key = -1;
      setFilteredList(items);
    } else {
      setFilteredList([...items.filter((item) => item.category === key)]);
    }
    setCategory(key);
  };
  return (
    <main>
      <Container maxWidth="lg">
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0.5,
              m: 0,
            }}
          >
            {categories.map((data) => {
              return (
                <ListItem key={data.key}>
                  <Chip
                    size="small"
                    sx={{
                      backgroundColor:
                        selectedCategory === data.key
                          ? "black"
                          : `${categoryColor[data.key]}`,
                      fontWeight: "bold",
                      fontSize: 10,
                      boxShadow: selectedCategory === data.key ? 5 : 0,
                      color: selectedCategory === data.key ? "white" : "black",
                      "&:hover": {
                        backgroundColor:
                          selectedCategory === data.key
                            ? "black"
                            : `${categoryColor[data.key]}`,
                      },
                    }}
                    clickable
                    onClick={() => categoryHandler(data.key)}
                    label={data.label}
                  />
                </ListItem>
              );
            })}
          </Grid>
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              justifyContent: "left",
              marginLeft: "30px",
              marginRight: "30px",
              flexWrap: "wrap",
              p: 0.5,
            }}
          >
            {filteredList ? (
              filteredList.map((item) => {
                return <ItemCard key={item.accessKey} {...item} />;
              })
            ) : (
              <LoadingSpinner />
            )}
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default MainBody;
