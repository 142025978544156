import { Typography } from "@mui/material";
import Link from "@mui/material/Link";

export function Copyright(props: any) {
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        position="fixed"
        bottom="70px"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://www.yamijo.com" underline="none">
          Yamijo
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        position="fixed"
        bottom="40px"
      >
        SC740892
      </Typography>
    </>
  );
}
