import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, Auth } from "firebase/auth";
import config from "./config";

let auth: Auth;
const app = initializeApp(config.firebase);
if (process.env.NODE_ENV === "development") {
  auth = getAuth();
  connectAuthEmulator(auth, "http://127.0.0.1:9009");
} else {
  auth = getAuth(app);
}

export { auth };
