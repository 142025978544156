import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import categoryReducer from "../features/categorySlice";
import itemReducer from '../features/itemSlice';

export const store = configureStore({
    reducer:{
        user: userReducer,
        categories: categoryReducer,
        item: itemReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;