import { useState, useContext, useEffect, CSSProperties } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { AuthContext } from "../context/AuthContext";
import yamijo from "../assets/images/yamijo.png";
import {
  Alert,
  AlertColor,
  CircularProgress,
  Collapse,
  Container,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Copyright } from "./copyRight";
import { IAuth } from "../types/types";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
// import { sendPasswordResetEmail } from "firebase/auth";

type userData = { email: string; password: string; confirmPassword?: string };

// quickHowTo

const quickHowTo: CSSProperties = {
  fontSize: "2vw",
  // right: 30,
};
// const quickHowToHeader: CSSProperties = {
//   fontSize: "3.5vw",
//   color: "black",
//   fontWeight: "bold",
//   paddingTop: "10px",
//   position: "absolute",
//   top: 20,
//   right: 30,
// };

export default function SignIn(): JSX.Element {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isSignIn, setIsSignIn] = useState<boolean>(true);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const { user, signUp, login } = useContext(AuthContext) as IAuth;
  const [severity, setSeverity] = useState<AlertColor>("error");
  const [isConnecting, setConnect] = useState<boolean>(false);
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const navigator = useNavigate();

  // <CircularProgress  sx={{color: "white"}}/>
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<userData>();

  useEffect(() => {
    if (user) {
      if (user.emailVerified) {
        navigator("/home");
        return;
      }
      navigator("/emailVerification");
    }
  });

  const onSubmit = async (data: userData) => {
    setConnect(true);
    try {
      if (isSignIn) {
        await login({ ...data });
        setConnect(false);
        navigator("/home");
      } else {
        const { email, password, confirmPassword } = data;
        if (password === confirmPassword && email) {
          await signUp({ ...data });
          setConnect(false);
          linkHandler();
        }
      }
    } catch (e: any) {
      setAlertMessage(e.error);
      setShowAlert(true);
      setSeverity("error");
      setConnect(false);
      return;
    }
  };

  const linkHandler = () => {
    clearErrors();
    setIsSignIn(!isSignIn);
    setIsSignUp(!isSignUp);
  };

  // const sendPasswordResetEmailHandler = () => {
  //   navigator("/sendPasswordReset");
  // };

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          display="flex"
          xs={12}
          sm={12}
          md={4}
          sx={{
            backgroundColor: "#f6fafd",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: { xs: "none", md: "flex" },
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "80%",
              height: "50%",
              bgcolor: "#cfe8fc",
              position: "relative",
            }}
          >
            <div
              style={{
                top: "0",
                left: "0",
                zIndex: 10000,
              }}
            >
              <Avatar
                src={yamijo}
                sx={{ m: 1, width: "10vw", height: "10vw" }}
              />
            </div>
            <List
              sx={{ width: "100%", maxWidth: 360 }}
              aria-labelledby="nested-list-subheader"
            >
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="lg"
                    style={{ color: "#021d32" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Register on the platform."
                  style={quickHowTo}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="lg"
                    style={{ color: "#021d32" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Search for a household item."
                  style={quickHowTo}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="lg"
                    style={{ color: "#021d32" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Request for an available item."
                  style={quickHowTo}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="lg"
                    style={{ color: "#021d32" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Use & return in good condition."
                  style={quickHowTo}
                />
              </ListItem>
            </List>
          </Box>
        </Grid>

        <Grid
          item
          justifyContent="center"
          alignItems="center"
          display="flex"
          xs={12}
          sm={12}
          md={8}
        >
          <Container
            style={{
              position: "absolute",
              top: 0,
              paddingTop: "20px",
              width: "50%",
            }}
          >
            {showAlert && (
              <Collapse in={showAlert}>
                {
                  <Alert
                    sx={{
                      "& .MuiAlert-message": {
                        width: "100%",
                      },
                    }}
                    severity={severity}
                    onClick={() => setShowAlert(false)}
                  >
                    {alertMessage}
                  </Alert>
                }
              </Collapse>
            )}
          </Container>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "40%",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#021d32" }} variant="rounded">
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {isSignIn ? "Sign in" : "Register"}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                error={errors.email ? true : false}
                {...register("email", {
                  required: "Please Enter Your Email!",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please Enter A Valid Email!",
                  },
                })}
              />
              <Typography color="red" fontSize={12}>
                {errors.email?.message}
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type={passwordVisibility ? "text" : "password"}
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FontAwesomeIcon
                        icon={passwordVisibility ? faEyeSlash : faEye}
                        size="sm"
                        cursor="pointer"
                        onClick={() => {
                          setPasswordVisibility(!passwordVisibility);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
                error={errors.password ? true : false}
                {...register("password", {
                  required: "Please Enter Your Password",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long!",
                  },
                })}
              />
              <Typography color="red" fontSize={12}>
                {errors.password?.message}
              </Typography>
              {isSignUp && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-password"
                    error={errors.confirmPassword ? true : false}
                    {...register("confirmPassword", {
                      required: "Please Enter Confirm Password",
                      minLength: {
                        value: 8,
                        message: "",
                      },
                    })}
                  />
                  <Typography color="red" fontSize={12}>
                    {errors.confirmPassword?.message}
                  </Typography>
                </>
              )}
              {isSignIn && (
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isConnecting}
              >
                {isConnecting ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : isSignIn ? (
                  "Login"
                ) : (
                  "Register"
                )}
              </Button>
              {/* <Grid container>
                <Grid item xs style={{ textAlign: "start"}}>
                  <Link href="#" variant="body2" onClick={sendPasswordResetEmailHandler} underline="none" fontWeight="bolder">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2" onClick={linkHandler}  underline="none" fontWeight="bold">
                    {isSignUp ? "Log in" : <><Typography color='black' variant="body1" display="inline">Don't have an account? </Typography>Sign up</>}
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
          <Copyright />
        </Grid>
      </Grid>
    </>
  );
}
